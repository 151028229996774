.sh-quality-switcher {
    border: 1px solid var(--sh-global-color__white);
    border-radius: var(--sh-global-border-radius);
    .sh-quality-switcher__button {
        // border: 1px solid var(--sh-global-color__white);
        border: none;
        background: none;
        color: var(--sh-global-color__white);
        padding: 4px 12px;
        font-size: 14px;
        cursor: pointer;
        font-weight: 500;
        &:disabled {
            cursor: not-allowed;
            color: var(--sh-global-color__black);
            background: var(--sh-global-color__white);
        }
        @media only screen and (max-width: 600px) {
            padding: 4px 12px;
            font-size: 12px;
        }
    }
}