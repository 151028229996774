@import "~video-react/styles/scss/video-react.scss";

.sh-video-player {
    margin: 20px auto;
    max-width: 70%;
    background: var(--sh-global-color__grey-100);
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.11);
    padding: 16px;
    border-radius: var(--sh-global-border-radius);
    @media only screen and (max-width: 1000px) {
        max-width: 90%;
    }
    @media only screen and (max-width: 600px) {
        max-width: 100%;
    }
    .video-react-big-play-button {
        border-radius: 50px;
        border: 2px solid var(--sh-global-color__accent);
    }
}
