@mixin videoPlacement {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 101%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translateX(-50%) translateY(-50%);
    background-size: cover;
    transition: 1s opacity;
}

.sh-video-hero { 
    z-index: -100;
    @include videoPlacement;
}

.sh-video-overlay {
    z-index: -99;
    background: var(--sh-global-color__black);
    opacity: .95;
    @include videoPlacement;
}

@media only screen and (max-width: 600px) {
    .sh-video-hero { display: none; }
    .sh-video-overlay { display: none; }
}