.sh-select {
    display: flex;
    flex-direction: column;
    &__input {
        text-transform: capitalize;
        border-radius: var(--sh-global-border-radius);
        background: var(--sh-global-color__white);
        border: 1px solid var(--sh-global-color__grey-100);
        padding: 5px 12px;
        font-size: 14px;
    }
}