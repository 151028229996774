.sh-badge {
    display: inline-block;
    background: var(--sh-global-color__accent);
    color: var(--sh-global-color__black);
    border-radius: 20px;
    font-size: 12px;
    padding: 4px 12px;
    white-space: nowrap;
    @media only screen and (max-width: 600px) {
        font-size: 10px;
        padding: 2px 6px;
    }
}

