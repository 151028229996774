@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root { 
  height: 100vh;
  z-index: -101;
  display: grid;
  grid-template: 100px / auto;

  @media only screen and (max-width: 600px) {
    grid-template: 140px / auto;
  }
}

h1, h2, h3, h4, h5, h6 {
  padding: 0;
  margin: 0;
}